import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";

class AppWindow {
	constructor() {
    const header = new Header;
    const body = new Body;
    const footer = new Footer;
	}
}

export default AppWindow;